<template>
  <div :class="name">
    <div v-if="$router.currentRoute.name === 'passengers-passenger'">
      <h1 class="text-xs-center">Passenger Details</h1>
      <br />
      <v-form v-if="currentPassenger && !currentPassengerError" @submit.prevent="update()" ref="form">
        <div class="layout justify-space-between mb-2">
          <div>
            <v-btn @click="registerAlternateIdentifier()" small :disabled="apiInProgress || isNonValidPassenger()"><v-icon small>fal fa-signal-stream</v-icon> &nbsp; Register Beacon/Tag</v-btn>
            <v-btn small :disabled="apiInProgress || isNonValidPassenger()" :to="{ name: 'promotions-adhocpromotion', query: { phone: currentPassenger.passengerIdentifier.phoneNumber } }"><v-icon left small>fal fa-hand-holding-usd</v-icon> Send Promotional Credit</v-btn>
          </div>
          <div>
            <v-btn color="warning mr-2" flat @click.stop="loadParentView" :disabled="apiInProgress">Back to list</v-btn>
<!--            <v-btn color="primary" type="submit" v-if="hasUserType('admin')" :disabled="apiInProgress || !isDataEdited()" :loading="apiInProgress">Save</v-btn>-->
          </div>
        </div>
        <v-alert v-show="currentPassenger.dateArchived">
          <h3 class="text-xs-center">This passenger is archived</h3>
        </v-alert>
        <v-alert type="info" v-show="currentPassenger.activationCode && currentPassenger.activationCodeExpiryLabel">
          <h3 class="text-xs-center">This passenger is pending activation</h3>
        </v-alert>
        <v-alert type="info" v-show="currentPassenger.transferCode && currentPassenger.transferCodeExpiryLabel">
          <h3 class="text-xs-center">This passenger is pending a transfer</h3>
        </v-alert>
        <v-layout row wrap>
          <v-flex sm5 pa-2>
            <v-layout row align-center>
              <v-flex sm5 pa-2 class="text-xs-right">
                <span v-if="!currentPassenger.dateArchivedLabel"><strong>Not archived</strong></span>
                <span v-if="currentPassenger.dateArchivedLabel"><strong>Archived: {{currentPassenger.dateArchivedLabel}}</strong></span>
              </v-flex>
              <v-flex sm7 pa-2>
                <v-btn @click="archivePassenger(currentPassenger)" small class="warning darken-1" v-show="!isNonValidPassenger()" :disabled="apiInProgress"><v-icon left small>fal fa-archive</v-icon> Archive</v-btn>
                <v-btn @click="loadUnarchiveDialog(currentPassenger)" small class="warning darken-1" v-show="isNonValidPassenger()" :disabled="apiInProgress"><v-icon left small>fal fa-undo</v-icon> Unarchive</v-btn>
              </v-flex>
            </v-layout>
            <v-layout row align-center v-if="!isNonValidPassenger() && hasAdminPermissions">
              <v-flex sm5 pa-2 class="text-xs-right">
                <strong>Manage password</strong>
              </v-flex>
              <v-flex sm7 pa-2>
                <v-btn small @click="sendNewPassword(currentPassenger)" :disabled="apiInProgress"><v-icon left small>fal fa-key</v-icon> Send New Password</v-btn>
              </v-flex>
            </v-layout>
            <v-layout row align-center v-if="currentPassenger && !isNonValidPassenger() && hasAdminPermissions">
              <v-flex sm5 pa-2 class="text-xs-right">
                <strong>Re-registration restrictions</strong>
              </v-flex>
              <v-flex sm7 pa-2>
                <span v-if="!currentPassenger.passengerIdentifier.dateReRegistrationGuarded">Re-registration Allowed</span>
                <v-btn v-if="currentPassenger.passengerIdentifier.dateReRegistrationGuarded" small @click="clearReRegistrationGuard(currentPassenger)" :disabled="apiInProgress">Allow Re-registration</v-btn>
              </v-flex>
            </v-layout>
            <v-layout row align-center>
              <v-flex sm5 pa-2 class="text-xs-right">
                <strong>Last updated</strong>
              </v-flex>
              <v-flex sm7 pa-2>
                {{currentPassenger.dateUpdatedLabel || 'N/A'}}
              </v-flex>
            </v-layout>
            <v-layout row align-center>
              <v-flex sm5 pa-2 class="text-xs-right">
                <strong>Date created</strong>
              </v-flex>
              <v-flex sm7 pa-2>
                {{currentPassenger.dateCreatedLabel}}
              </v-flex>
            </v-layout>
            <v-layout row align-center v-if="currentPassenger.activationCodeExpiryLabel">
              <v-flex sm5 pa-2 class="text-xs-right">
                <strong>Activation code expiry</strong>
              </v-flex>
              <v-flex sm7 pa-2>
                {{currentPassenger.activationCodeExpiryLabel}}
              </v-flex>
            </v-layout>
            <v-layout row align-center v-if="currentPassenger.transferCodeExpiryLabel">
              <v-flex sm5 pa-2 class="text-xs-right">
                <strong>Transfer code expiry</strong>
              </v-flex>
              <v-flex sm7 pa-2>
                {{currentPassenger.transferCodeExpiryLabel}}
              </v-flex>
            </v-layout>
            <v-layout row align-top>
              <v-flex sm5 pa-2 class="text-xs-right">
                <strong>Recovery contact</strong>
              </v-flex>
              <v-flex sm7 pa-2>
                {{currentPassenger.stUser && currentPassenger.stUser.metadata && currentPassenger.stUser.metadata.recoveryContact ? currentPassenger.stUser.metadata.recoveryContact : 'N/A'}}
                <div v-if="currentPassenger && !isNonValidPassenger() && hasAdminPermissions">
                  <v-btn small @click="loadRecoveryContactDialog()">Update Recovery Contact</v-btn>
                </div>
              </v-flex>
            </v-layout>
            <v-layout row align-top>
              <v-flex sm5 pa-2 class="text-xs-right">
                <strong>Refund restrictions (from agent staff top ups)</strong>
              </v-flex>
              <v-flex sm7 pa-2>
                {{currentPassenger && currentPassenger.agentStaffTopUpDeductionsExpiryLabel ? `Permitted until ${currentPassenger.agentStaffTopUpDeductionsExpiryLabel}` : 'Restricted'}}
                <div v-if="currentPassenger && !isNonValidPassenger() && hasAdminPermissions">
                  <v-btn small @click="loadAgentStaffRefundDialog()">Set Permitted Until Date</v-btn>
                </div>
              </v-flex>
            </v-layout>
            <v-layout row align-top v-if="currentPassenger && !isNonValidPassenger() && hasAdminPermissions">
              <v-flex sm5 pa-2 class="text-xs-right">
                <strong>Confirmation code(s)</strong>
              </v-flex>
              <v-flex sm7 pa-2>
                <div>Refresh: <v-btn fab small :disabled="apiInProgress" @click="refreshConfirmationCodes()"><v-icon>far fa-sync-alt</v-icon></v-btn></div>
                <div v-if="displayConfirmationCodes">
                  <ul v-if="currentPassenger.passengerIdentifiers && currentPassenger.passengerIdentifiers.length">
                    <li v-if="currentPassenger.passengerIdentifiers[0].activationCode">
                      <span>{{currentPassenger.stUser.metadata && currentPassenger.stUser.metadata.recoveryContact ? 'Recovery contact confirmation' : 'Activation code'}}</span>
                       <code>{{currentPassenger.passengerIdentifiers[0].activationCode}}</code>
                       <small v-if="currentPassenger.activationCodeExpiryLabel">
                         Expires {{currentPassenger.activationCodeExpiryLabel}}
                       </small>
                    </li>
                    <li v-if="currentPassenger.passengerIdentifiers[0].transferCode">
                      <span>Transfer code <small>(used for agent app confirmations or passenger phone transfers)</small></span>
                       <code>{{currentPassenger.passengerIdentifiers[0].transferCode}}</code>
                       <small v-if="currentPassenger.transferCodeExpiryLabel">
                         Expires {{currentPassenger.transferCodeExpiryLabel}}
                       </small>
                    </li>
                  </ul>
                  <div v-if="!currentPassenger.passengerIdentifiers || !currentPassenger.passengerIdentifiers.length">N/A</div>
                </div>
              </v-flex>
            </v-layout>
            <v-layout row align-center>
              <v-flex sm5 pa-2 class="text-xs-right">
                <strong>Country detected</strong>
              </v-flex>
              <v-flex sm7 pa-2>
                {{currentPassenger.currentCountryIdDetectedLabel}}
              </v-flex>
            </v-layout>
            <v-layout row align-center>
              <v-flex sm5 pa-2 class="text-xs-right">
                <strong>Country set</strong>
              </v-flex>
              <v-flex sm7 pa-2>
                {{currentPassenger.currentCountryIdSetLabel}}
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex sm7 pa-2>
            <h2>Phone number</h2>
            <div class="layout justify-start align-start mb-4">
              <v-icon large>far fa-mobile-alt</v-icon>
              <div class="ml-2">
                <h2>{{currentPassenger.passengerIdentifier ? currentPassenger.passengerIdentifier.phoneNumber : 'N/A'}}</h2>
                <div v-if="currentPassenger.passengerIdentifier">
                  <v-icon class="success--text" small v-show="currentPassenger.passengerIdentifier.verified">far fa-check</v-icon>
                  <v-icon class="error--text" small v-show="!currentPassenger.passengerIdentifier.verified">far fa-times</v-icon> Verified
                </div>
                <div v-if="currentPassenger.passengerIdentifier">Updated: {{currentPassenger.passengerIdentifier.dateUpdatedLabel}}</div>
                <div v-if="currentPassenger.passengerIdentifier">Created: {{currentPassenger.passengerIdentifier.dateCreatedLabel}}</div>
              </div>
              <div class="ml-3" v-if="currentPassenger.passengerIdentifier || (hasAdminPermissions && currentPassenger.stUser.metadata && currentPassenger.stUser.metadata.mobileMoneyProfile)">
                <div v-if="currentPassenger.passengerIdentifier">
                  <div>Last access type: {{currentPassenger.lastAccessType}}</div>
                  <div>Device details:</div>
                  <div class="px-2 my-2" style="max-height: 100px; max-width: 350px; overflow: auto">
                    <pre>{{currentPassenger.passengerIdentifier.osVersionLabel}}</pre>
                  </div>
                </div>
                <div v-if="hasAdminPermissions">
                  <div>Mobile money profile: <v-btn fab small :disabled="apiInProgress" @click="refreshMobileMoneyProfile()"><v-icon>far fa-sync-alt</v-icon></v-btn></div>
                  <div class="px-2 my-2" style="max-height: 100px; max-width: 350px; overflow: auto" v-if="currentPassenger.stUser.metadata && currentPassenger.stUser.metadata.mobileMoneyProfile">
                    <pre>{{currentPassenger.stUser.metadata.mobileMoneyProfile}}</pre>
                  </div>
                </div>
              </div>
            </div>
            <h2>Balance</h2>
            <div class="layout justify-start mb-4">
              <v-icon large>far fa-wallet</v-icon>
              <h2 :class="'ml-2' + (currentPassenger.balance > 0 ? ' success--text' : (currentPassenger.balance < 0 ? ' error--text' : ''))">{{currentPassenger.balanceLabel}}</h2>
            </div>
            <h2>Last known trip</h2>
            <div class="layout justify-start align-start mb-4">
              <v-icon large>far fa-ticket-alt</v-icon>
              <div class="ml-2">
                <div>{{currentPassenger.lastKnownTripLabel || 'N/A'}}</div>
                <div v-if="currentPassenger.passengerTrip">{{currentPassenger.passengerTrip.dateUpdatedLabel}}</div>
              </div>
            </div>
            <h2>Last used beacon/tag</h2>
            <div class="layout justify-start align-start mb-4">
              <v-icon large>far fa-signal-stream</v-icon>
              <div class="ml-2">
                <div>
                  <code v-if="currentPassenger.passengerAlternateIdentifier">{{currentPassenger.passengerAlternateIdentifier.identifierKey}}</code>
                  <span v-show="!currentPassenger.passengerAlternateIdentifier">N/A</span>
                </div>
                <div v-if="currentPassenger.passengerAlternateIdentifier && currentPassenger.passengerAlternateIdentifier.dateArchivedLabel">Archived: {{currentPassenger.passengerAlternateIdentifier.dateArchivedLabel}}</div>
                <div v-if="currentPassenger.passengerAlternateIdentifier">Updated: {{currentPassenger.passengerAlternateIdentifier.dateUpdatedLabel}}</div>
                <div v-if="currentPassenger.passengerAlternateIdentifier">Created: {{currentPassenger.passengerAlternateIdentifier.dateCreatedLabel}}</div>
                <div class="my-2">
                  <router-link :to="{ name: 'passengers-passenger-alternate-identifiers' }">Add/View identifiers</router-link>
                </div>
              </div>
            </div>
          </v-flex>
        </v-layout>
        <div class="layout justify-end">
          <v-btn color="warning mr-2" flat @click.stop="loadParentView" :disabled="apiInProgress">Back to list</v-btn>
        </div>
      </v-form>
      <v-dialog
        transition="dialog-bottom-transition"
        scrollable
        persistent
        max-width="500"
        v-model="agentStaffRefundDialogLoaded">
        <v-card>
          <v-card-title class="headline grey lighten-2 text-xs-center" primary-title>
            Refund Restrictions
          </v-card-title>
          <v-form>
            <v-card-text>
              <div><strong>Allow refunds until this date</strong></div>
              <div style="max-width: 300px" v-if="!agentStaffRefundExpiryDisabled">
                <v-menu
                  v-model="agentStaffRefundExpiryMenu"
                  :close-on-content-click="false"
                  full-width
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      prepend-icon="fal fa-calendar"
                      :value="computedAgentStaffRefundExpiryFormatted"
                      @click:clear="agentStaffRefundExpiry = null"
                      clearable
                      label="Select date"
                      readonly
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    no-title
                    :min="computedAgentStaffRefundExpiryMin"
                    v-model="agentStaffRefundExpiry"
                    @change="agentStaffRefundExpiryMenu = false"
                  />
                </v-menu>
              </div>
              <div class="mt-3">
                <v-switch v-model="agentStaffRefundExpiryDisabled" label="Remove all permissions" />
              </div>
            </v-card-text>
          </v-form>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn color="warning" flat :disabled="savingRecoveryContact" @click="cancelAgentStaffRefundDialog()">
              Cancel
            </v-btn> &nbsp;
            <v-btn
              color="primary"
              :disabled="savingAgentStaffRefundExpiry"
              :loading="savingAgentStaffRefundExpiry"
              @click="saveAgentStaffRefundExpiry()"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        transition="dialog-bottom-transition"
        scrollable
        persistent
        max-width="500"
        v-model="recoveryContactDialogLoaded">
        <v-card>
          <v-card-title class="headline grey lighten-2 text-xs-center" primary-title>
            Add/Update Recovery Contact
          </v-card-title>
          <v-form>
            <v-card-text>
              <v-layout justify-center class="mb-2">
                <v-btn-toggle v-model="recoveryContactType">
                  <v-btn small flat value="mobile">
                    <span>Mobile phone</span>
                  </v-btn>
                  <v-btn small flat value="email">
                    <span>Email</span>
                  </v-btn>
                </v-btn-toggle>
              </v-layout>
              <v-text-field v-show="recoveryContactType === 'email'" v-model="recoveryContactEmail" :rules="emailRules" validate-on-blur prepend-icon="fal fa-user" name="email" label="Email address" type="email"></v-text-field>
              <div v-show="recoveryContactType === 'mobile'"><phone-number-field v-model="recoveryContactPhone" :child-props="{ label: 'Mobile number' }" /></div>
            </v-card-text>
          </v-form>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn color="warning" flat :disabled="savingRecoveryContact" @click="cancelRecoveryContactDialog()">
              Cancel
            </v-btn> &nbsp;
            <v-btn color="primary"
               :disabled="savingRecoveryContact"
               :loading="savingRecoveryContact"
               @click="saveRecoveryContact()">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
          transition="dialog-bottom-transition"
          scrollable
          persistent
          max-width="500"
          v-model="unarchiveDialogLoaded">
        <v-card>
          <v-card-title class="headline grey lighten-2 text-xs-center" primary-title>
            Unarchive passenger
          </v-card-title>
          <v-form>
            <v-card-text>
              <h3>Please select deleted phone number to recover</h3>
              <v-select
                  pb-2
                  :disabled="isLoadingUnarchivePassengerIdentifiers"
                  :items="unarchivePassengerIdentifiers"
                  v-model="selectedUnarchivePassengerIdentifier"
                  label="Passenger number to recover"
              />
            </v-card-text>
          </v-form>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn color="warning" flat :disabled="isLoadingUnarchivePassengerIdentifiers" @click="unarchiveDialogLoaded = false">
              Cancel
            </v-btn> &nbsp;
            <v-btn color="primary"
                   :disabled="isLoadingUnarchivePassengerIdentifiers || !selectedUnarchivePassengerIdentifier"
                   :loading="isLoadingUnarchivePassengerIdentifiers"
                   @click="undoArchivePassenger(unarchiveDialogPassenger, selectedUnarchivePassengerIdentifier)">
              Unarchive
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-alert :value="currentPassengerError" type="error">
        <p>{{currentPassengerError}}</p>
        <p><a href @click.stop="refreshView()">Retry</a> or <router-link :to="{ name: 'passengers' }">Return to passengers list</router-link></p>
      </v-alert>
    </div>
    <router-view
      v-if="currentPassenger"
      :force-root-view-refresh="forceRootViewRefresh"
      :current-passenger="currentPassenger"
      :signed-in-user="signedInUser"
      :alternate-identifier-types="alternateIdentifierTypes"
      :has-user-type="hasUserType">
    </router-view>
  </div>
</template>

<script>
import src from './src'
export default src
</script>
